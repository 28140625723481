<template>
  <BaseCard
    multi-section
    style="cursor: pointer"
    :title="title"
    :subtitle="subtitle"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <template #header-action>
      <StatusBadge :value="maintenance.status.id" class="q-mt-sm q-ml-auto" />

      <q-badge
        v-if="maintenance.do_immediatly"
        label="Da fare subito"
        color="pink"
        class="q-mt-sm q-ml-md"
      />

      <q-badge
        v-if="maintenance.mandatory"
        label="Tassativo"
        color="purple"
        class="q-mt-sm q-ml-md"
      />
    </template>

    <div class="flex">
      <template v-for="(sections, indexSection) in sectionsList">
        <div :key="`sections_${indexSection}`" class="q-mr-xl">
          <q-list dense class="q-pb-md">
            <template v-for="(section, name) in sections">
              <q-item :key="`maintenane_label${name}`" class="q-mx-sm q-my-xs">
                <q-item-section avatar>
                  <q-icon color="primary" :name="section.icon" />
                </q-item-section>

                <q-item-section>
                  <div class="flex items-center">
                    <span v-html="sectionValue(sections, name)" />
                    <q-item-label
                      v-if="!!section.label"
                      caption
                      class="q-ml-sm"
                    >
                      <q-icon
                        name="fiber_manual_record"
                        style="font-size: 6px"
                        class="q-mr-xs"
                      />
                      {{ section.label }}
                    </q-item-label>
                  </div>
                </q-item-section>
              </q-item>
            </template>
          </q-list>
        </div>
      </template>
    </div>
  </BaseCard>
</template>

<script>
import StatusBadge from "./StatusBadge.vue";
import { computed } from "@vue/composition-api";
import { date } from "quasar";
import { useGetters } from "../../hooks/store.js";

export default {
  name: "MaintenanceSearchCard",

  components: {
    StatusBadge,
  },

  props: {
    maintenance: Object,
  },

  setup(props) {
    const title = computed(
      () =>
        `${props.maintenance.vehicle.model.brand.name || ""} - ${
          props.maintenance.vehicle.model.name || ""
        } ${props.maintenance.vehicle.model.year || ""}`
    );
    const subtitle = computed(() => props.maintenance.customer.fullname);

    const { getKind } = useGetters("maintenance", ["getKind"]);

    const sectionsList = [
      {
        plate_number: {
          icon: "money",
          label: "targa",
          value: () => props.maintenance.vehicle.plate_number,
        },
        serial_number: {
          icon: "mdi-card-bulleted-outline",
          label: "N° di serie",
          value: () => props.maintenance.vehicle.serial_number,
        },
        frame_number: {
          icon: "mdi-car-limousine",
          label: "N° di telaio",
          value: () => props.maintenance.vehicle.frame_number,
        },
        kind: {
          icon: "mdi-tools",
          label: "Genere",
          value: (value) => getKind(value).value.label,
        },
      },
      {
        registration_date: {
          icon: "mdi-calendar-arrow-left",
          label: "Data di arrivo",
          value: (value) => date.formatDate(value, "DD/MM/YYYY"),
        },
        delivery_date: {
          icon: "mdi-calendar-arrow-right",
          label: "Data di uscita",
          value: (value) => date.formatDate(value, "DD/MM/YYYY"),
        },
        user: {
          icon: "mdi-face-agent",
          label: "Consulente",
        },
        creator: {
          icon: "mdi-account-child",
          label: "Operatore",
        },
      },
      {
        email: {
          icon: "mdi-at",
          label: "Email",
          value: () => {
            return props.maintenance.customer.email
              ? `<a class="text-pink" href="mailto:${props.maintenance.customer.email}">${props.maintenance.customer.email}</a>`
              : "-";
          },
        },
        phone: {
          icon: "mdi-phone-outline",
          label: "Principale",
          value: () => props.maintenance.customer.phone,
        },
        phone2: {
          icon: "mdi-phone-plus-outline",
          label: "Secondario",
          value: () => props.maintenance.customer.phone2 || "-",
        },
      },
    ];

    function sectionValue(sections, name) {
      if (sections[name].value == undefined)
        return props.maintenance[name] || "-";

      return sections[name].value(props.maintenance[name]);
    }

    return {
      title,
      subtitle,
      sectionsList,
      sectionValue,
    };
  },
};
</script>
