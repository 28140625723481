<template>

  <BaseCard
    dark
    v-bind="$attrs">

    <BaseBtn
      color="pink"
      class="lt-md full-width q-mb-md"
      icon="mdi-filter-outline"
      :label="selectedType">

      <q-tooltip anchor="top middle">Seleziona il tipo di ricerca</q-tooltip>

      <q-menu auto-close>

        <template v-for="typeOption in types">
          <q-item
            clickable
            @click="filters.type = typeOption.value"
            :active="typeOption.value == filters.type"
            :key="`menu_${typeOption.value}`">

            <q-item-section>
              {{ typeOption.label }}
            </q-item-section>

          </q-item>
        </template>

      </q-menu>
    </BaseBtn>


    <BaseInput
      placeholder="Inserisci il testo da cercare"
      v-model="filters.query"
      @keyup.enter="submit"
      dark>

      <template #prepend>

        <BaseBtn
          color="pink"
          class="gt-sm"
          icon="mdi-filter-outline"
          :label="selectedType">

          <q-tooltip anchor="top middle">Seleziona il tipo di ricerca</q-tooltip>

          <q-menu auto-close>

            <template v-for="typeOption in types">
              <q-item
                clickable
                @click="filters.type = typeOption.value"
                :active="typeOption.value == filters.type"
                :key="`menu_${typeOption.value}`">

                <q-item-section>
                  {{ typeOption.label }}
                </q-item-section>

              </q-item>
            </template>

          </q-menu>
        </BaseBtn>

      </template>

      <template #after>

        <BaseBtn
          @click="submit"
          :disabled="!canSearch"
          class="full-width q-pa-sm gt-sm"
          color="primary"
          label="Cerca" />

      </template>

      <template #hint>
        Seleziona il tipo di ricerca e inserisci almeno 3 caratteri
      </template>

    </BaseInput>

    <BaseBtn
      @click="submit"
      :disabled="!canSearch"
      class="full-width q-pa-sm q-mt-md lt-md"
      color="primary"
      label="Cerca" />

  </BaseCard>

</template>

<script>
import { computed, reactive, toRefs, watch } from '@vue/composition-api'

export default {
  name : 'SearchFormHome',

  props : {
    value: {
      type : Object,
    },

  },

  setup( props, { emit } )
  {
    const state = reactive({
      filters : { ...props.value }
    })

    watch(
      () => props.value,
      value => {
        state.filters = ({ ...state.filters, ...value })
      },
      {
        immediate: true,
      })


      const types = [
        { value: 'plate_number',  label: 'Per Targa' },
        { value: 'frame_number',  label: 'Per N° Telaio' },
        { value: 'serial_number',  label: 'Per N° Serie' },
        { value: 'customer_name',  label: 'Per Cliente' },
      ]

      const selectedType = computed( () => types.find( type => state.filters.type == type.value ).label )

      const canSearch = computed( () => state.filters.query.length > 2 )

      function submit()
      {
        if( !canSearch.value ) return

        emit( 'submit', state.filters )
      }

      return {
        ...toRefs( state ),
        canSearch,
        selectedType,
        submit,
        types
      }

  },
}
</script>

