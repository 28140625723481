<template>
  <BasePage
    padding
    title="Dashboard"
    class="row justify-center items-start"
    subtitle="Schede di manutenzione"
  >
    <SearchFormHome
      v-bind="formAttrs"
      :value="filters"
      @submit="(newFilters) => (filters = newFilters)"
    />

    <template v-if="filters.query.length > 2">
      <BaseBanner
        v-if="!data.length"
        icon="mdi-alert-circle-outline"
        color="dark"
        message="Nessuna scheda trovata"
      />

      <template v-else v-for="result in data">
        <MaintenanceSearchCard
          :maintenance="result"
          @click="show(result.id)"
          :key="`result_${result.id}`"
        />
      </template>
    </template>

    <TaskDailyGrind />

    <BaseInnerLoading :showing="loading" />
  </BasePage>
</template>

<script>
import api from '../apis/maintenances.js'
import MaintenanceSearchCard from '../components/maintenances/MaintenanceSearchCard.vue'
import SearchFormHome from '../components/dashboard/SearchFormHome.vue'
import TaskDailyGrind from '../components/tasks/TaskDailyGrind.vue'
import useApiCrud from '../hooks/apiCrud.js'
import { useRouter } from '../hooks/router.js'
import { computed, toRefs } from '@vue/composition-api'
import { useActions } from '../hooks/store.js'

export default {
  name: 'Dashboard',
  components: {
    MaintenanceSearchCard,
    SearchFormHome,
    TaskDailyGrind,
  },

  setup(props, ctx) {
    const { loadStatus } = useActions('maintenance', ['loadStatus'])
    loadStatus()

    const route = useRouter().history.current

    const immediate = !!route.query.query

    const type = 'plate_number'
    const query = ''

    const formAttrs = computed(() =>
      state.filters.query.length > 2
        ? { class: 'shadow-24 wrapped', square: true }
        : {
            class: 'shadow-24 q-mt-xl q-mx-auto',
            style: 'width:600px;max-width:80vw',
          }
    )

    const { state, loading, show } = useApiCrud({
      api,
      ctx,
      immediate,
      routes: {
        index: 'home',
        show: 'maintenanceShow',
      },
      filters: {
        type,
        query,
      },
    })

    return {
      ...toRefs(state),
      formAttrs,
      loading,
      show,
    }
  },
}
</script>
