<template>
  <q-timeline color="primary" class="q-pt-lg">
    <BaseInnerLoading :showing="loading" />

    <q-separator />

    <q-timeline-entry class="q-pa-none">
      <div class="flex justify-between">
        <span class="text-h6"> Programmazione settimanale </span>

        <div class="q-gutter-md">
          <q-toggle v-model="showAll" label="Mostra completati">
            <q-tooltip>Mostra anche i task passati già completati</q-tooltip>
          </q-toggle>

          <BaseBtn icon="mdi-refresh" color="pink" @click="load">
            Aggiorna
          </BaseBtn>
        </div>
      </div>
    </q-timeline-entry>

    <q-timeline-entry
      v-if="!hasTasks"
      icon="mdi-clock-alert-outline"
      color="dark"
      subtitle="Nessuna operazione programmata"
    >
    </q-timeline-entry>

    <template v-else>
      <template v-for="day in data">
        <q-timeline-entry
          :title="formatDate(day.day, 'DD/MM/YYYY')"
          subtitle="Elenco attività"
          :key="`day_${day.day}`"
          :ref="`day_${day.day}`"
          icon="mdi-calendar"
        >
          <div class="row-sm column q-gutter-lg">
            <template v-for="task in day.data">
              <div
                class="col-md-auto"
                :style="$q.screen.lt.sm ? 'margin-right:1rem' : 'width: 350px'"
                :key="`task_${task.id}`"
              >
                <q-card
                  class="flex column"
                  :style="`border:4px solid ${task.user.color}`"
                >
                  <q-card-section class="q-pa-none col-grow">
                    <q-expansion-item expand-icon-class="hidden">
                      <template v-slot:header>
                        <q-item-section avatar>
                          <q-avatar
                            :icon="getStatus(task.status).value.icon"
                            :color="getStatus(task.status).value.color"
                            text-color="white"
                          />
                          <q-tooltip>
                            {{ getStatus(task.status).value.label }}
                          </q-tooltip>
                        </q-item-section>

                        <q-item-section>
                          {{ task.title }}

                          <q-tooltip> Mostra dettagli </q-tooltip>
                        </q-item-section>
                      </template>

                      <p class="text-grey-7 text-body2 q-px-md q-pt-sm">
                        {{ task.description }}
                      </p>
                    </q-expansion-item>
                  </q-card-section>

                  <q-separator />

                  <q-card-section class="q-py-sm">
                    <div class="flex justify-between">
                      <div class="text-subtitle2 q-pr-md">
                        <q-icon
                          name="mdi-information-outline"
                          style="margin-top: -2px"
                          color="pink"
                        />
                        Priorità
                      </div>
                      <span
                        :class="`text-${getPriorityColor(task.priority).value}`"
                      >
                        {{ getPriorityLabel(task.priority).value }}
                      </span>
                    </div>

                    <div class="flex justify-between">
                      <div class="text-subtitle2 q-pr-md">
                        <q-icon name="mdi-hard-hat" color="pink" />
                        Operatore
                      </div>
                      {{ task.user.name }}
                    </div>

                    <div class="flex justify-between">
                      <div class="text-subtitle2 q-pr-md">
                        <q-icon name="mdi-timer-outline" color="pink" />
                        Tempo stimato (ore)
                      </div>
                      {{ task.total_time }}
                    </div>
                  </q-card-section>

                  <q-separator />

                  <q-card-section class="q-py-sm">
                    <div class="text-subtitle2 flex">
                      <div>
                        <q-icon name="mdi-account-outline" color="pink" />
                        {{ task.customer.fullname }}
                      </div>

                      <CustomerContact
                        class="q-ml-auto"
                        size="xs"
                        :customer="task.customer"
                      />
                    </div>

                    <div class="text-subtitle2 flex justify-between">
                      <div>
                        <q-icon name="mdi-rv-truck" color="pink" />
                        {{ task.vehicle }}
                      </div>

                      <div v-if="!!task.vehicle_plate_number">
                        <q-icon name="mdi-card-text" color="pink" />
                        {{ task.vehicle_plate_number }}
                      </div>
                    </div>
                  </q-card-section>

                  <q-card-section class="q-pa-none">
                    <BaseBtn
                      flat
                      class="full-width"
                      icon-right="mdi-open-in-app"
                      color="secondary"
                      :to="{
                        name: 'maintenanceShow',
                        params: { id: task.maintenance_id },
                      }"
                    >
                      Apri scheda &nbsp;
                    </BaseBtn>
                  </q-card-section>
                </q-card>
              </div>
            </template>
          </div>
        </q-timeline-entry>
      </template>

      <q-timeline-entry class="q-pa-none" />
    </template>
  </q-timeline>
</template>

<script>
import Tasks from '../../apis/tasks.js'
import { date, scroll } from 'quasar'
import {
  computed,
  onUnmounted,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api'
import { useGetters } from '../../hooks/store.js'
import CustomerContact from '../customers/CustomerContact.vue'

export default {
  components: {
    CustomerContact,
  },

  setup(_, { refs }) {
    const state = reactive({
      loading: false,
      firstLoad: true,
      showAll: false,
      data: [],
    })

    const { getPriorityColor, getPriorityLabel, getStatus } = useGetters(
      'task',
      ['getPriorityColor', 'getPriorityLabel', 'getStatus']
    )

    const hasTasks = computed(() => {
      return Object.keys(state.data).length > 0
    })

    const { formatDate } = date

    let refresh = ''
    function load() {
      clearTimeout(refresh)

      state.loading = true
      Tasks.dailyGrind(state.showAll)
        .then((response) => (state.data = response.data))
        .then(() => {
          //refresh every 60 seconds
          refresh = setInterval(load, 60000)
        })
        .then(() => {
          if (!state.firstLoad) return

          //try to scroll to actual day
          const actualDay = formatDate(new Date(), 'YYYY-MM-DD')
          const actualDom = refs[`day_${actualDay}`] || undefined

          if (!actualDom) return

          //set scrolled
          state.firstLoad = false

          scroll.setScrollPosition(
            window,
            actualDom[0].$el.offsetTop - 100,
            400
          )
        })
        .finally(() => {
          state.loading = false
        })
    }

    //memory leak prevent
    onUnmounted(() => {
      clearTimeout(refresh)
    })

    watch(
      () => state.showAll,
      () => load(),
      { immediate: true }
    )

    return {
      formatDate,
      hasTasks,
      getPriorityColor,
      getPriorityLabel,
      getStatus,
      load,
      ...toRefs(state),
    }
  },
}
</script>
